import React, {Component} from 'react'
import { Drawer, Grid, Typography, Hidden, IconButton, List, ListItem, ListItemText } from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles'
import DehazeIcon from '@material-ui/icons/Dehaze'
import { ReactSVG } from 'react-svg'
import { Link } from 'react-scroll'
import WalletDialog from './Reusable/WalletDialog';
var text = require('../lang/en.json')

const styles = theme => ({
  appBar: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 250
  },
  barTextContainer: {
    textAlign: 'center',
    margin: 'auto',
    marginTop: theme.spacing(4)
  },
  barText: {
    '&:hover':{
      cursor:'pointer'
    },
    textDecoration: 'underline'
  },
  barLogo: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      height: 150,
      width: 150
    },
    [theme.breakpoints.up('md')]: {
      height: 200,
      width: 200
    },
    [theme.breakpoints.up('lg')]: {
      height: 250,
      width: 250
    }
  },
  barDrawerButton: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  barButtonFont: {
    fontSize: 40
  },
  detailsListContainer: {
    width: 250,
  }
})

class Navbar extends Component {
  constructor() {
    super()
    this.state = {
      drawerOpen: false,
      list: [
        {name: text['navbar']['learn'], link: text['routes']['learn'], newTab: 2},
        {name: text['navbar']['home'], link: text['routes']['home'], newTab: 2},
        {name: text['navbar']['whitePaper'], link: text['findUs']['whitePaper'], newTab: 1},
        {name: text['navbar']['howto'], link: text['routes']['howto'], newTab: 3},
        {name: text['navbar']['bscScan'], link: text['findUs']['bscLink'], newTab: 1},
        {name: text['navbar']['about'], link: text['findUs']['mediumLink'], newTab: 1},
      ]
    }
  }

  deleteHowTo = () => {
    this.setState(prevState => {
      var newList = prevState.list.filter(i => i.text === text['routes']['howto'])
      return {list: newList}
    })
  }

  openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  toggleDrawer = () => {
    this.setState({drawerOpen: !this.state.drawerOpen})
  }

  removeCurrentLocation = () => {
    this.setState(prevState => {
      var newList = prevState.list.filter(item => item.link !== window.location.pathname)
      if (window.location.pathname === text['routes']['learn'])
        newList = newList.filter(item => item.link !== text['routes']['howto'])
      return {list: newList}
    })
  }

  componentDidMount() {
    this.removeCurrentLocation();
  }

  renderTextLink = (item, index, classes) => {
    switch(item['newTab']) {
      case(1):
      return <Typography key={index} variant='h4' className={classes.barText} onClick={() => this.openInNewTab(item['link'])}>{item['name']}</Typography>
      case(2):
      return <Typography key={index} variant='h4' className={classes.barText} color='textPrimary' component="a" href={item['link']}>{item['name']}</Typography>
      case(3):
      return <Link key={index} to={item['link']} smooth><Typography variant='h4' className={classes.barText} color='textPrimary'>{item['name']}</Typography></Link>
      default:
        return (
          <>
          </>
        )
    }
  }

  renderListLink = (item, index) => {
    switch(item['newTab']) {
      case(1):
      return (
        <ListItem key={index} button onClick={() => this.openInNewTab(item['link'])}>
          <ListItemText primary={item['name']} primaryTypographyProps={{variant:'h4'}} />
        </ListItem>
      )
      case(2):
      return (
        <ListItem key={index} button component="a" href={item['link']}>
          <ListItemText primary={item['name']} primaryTypographyProps={{variant:'h4'}} />
        </ListItem>
      )
      case(3):
      return (
        <Link key={index} to={item['link']} smooth>
          <ListItem  button onClick={() => this.toggleDrawer()}>
            <ListItemText primary={item['name']} primaryTypographyProps={{variant:'h4'}} />
          </ListItem>
        </Link>
      )
      default:
        return (
          <>
          </>
        )
    }
  }

  render() {
    const {classes} = this.props;
    return (
      <Grid container justify='space-between' className={classes.appBar}>
        <Grid item xs={4}>
          <ReactSVG src='/pinocchu_logo.svg' wrapper={"svg"} className={classes.barLogo} />
        </Grid>
        <Grid item xs={2}>
          <WalletDialog />
        </Grid>
        <Hidden smDown>
          <Grid item xs={6}>
            <Grid container  className={classes.barTextContainer}>
              {this.state.list.map((item, index) => (
                <Grid key={index} item xs={Math.round(12 / this.state.list.length)}>
                  {this.renderTextLink(item, index, classes)}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={2} className={classes.barDrawerButton}>
            <IconButton onClick={() => this.toggleDrawer()}>
              <DehazeIcon className={classes.barButtonFont} />
            </IconButton>
          </Grid>
        </Hidden>
        <Drawer anchor='right' open={this.state.drawerOpen} onClose={() => this.toggleDrawer()}>
          <List className={classes.detailsListContainer}>
            {this.state.list.map((item, index) =>
              this.renderListLink(item, index)
            )}
          </List>
        </Drawer>
      </Grid>
    )
  }
}

export default withStyles(styles)(Navbar)