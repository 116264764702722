import React, {Component} from 'react'
import {withStyles} from '@material-ui/core/styles'
import { Container, Tab, Tabs } from '@material-ui/core';
import { TabPanel, TabContext} from '@material-ui/lab'
import Login from './Authenticate/Login';
import Register from './Authenticate/Register';
import BackgroundImage from './Reusable/BackgroundImage'
import Logout from './Authenticate/Logout';

const styles = theme => ({
  text: {
    fontFamily: 'ASTERA V2',
  }
})

class SignPage extends Component {
  constructor() {
    super();
    this.state = {index: "1"}
  }

  handleChange = (event, value) => {
    this.setState({index: value})
  }

  render() {
    const {classes} = this.props;
    return(
      <BackgroundImage imageUrl='background.png'>
      <Container>
        <TabContext value={this.state.index}>
        <Tabs
        value={this.state.index}
        onChange={this.handleChange}
        variant='fullWidth'>
          <Tab className={classes.text} label='Sign In' value={"1"}/>
          <Tab className={classes.text} label='Sign Up' value={"2"} disabled={sessionStorage.getItem('token') === null}/>
        </Tabs>
        <TabPanel value={"1"} index={this.state.index}>
          {sessionStorage.getItem('token') !== null ?
          <Logout /> :
          <Login />
          }
        </TabPanel>
        <TabPanel value={"2"} index={this.state.index}>
          <Register />
        </TabPanel>
        </TabContext>
      </Container>
      </BackgroundImage>
    )
  }
}

export default withStyles(styles)(SignPage)