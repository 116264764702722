import React, { Component } from 'react'
import { Container, Grid, Typography, Button } from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import BackgroundImage from '../Reusable/BackgroundImage'
var text = require('../../lang/en.json')

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 700,
    display: 'flex',
    alignItems: 'center'
  },
  mainGrid: {
    textAlign: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  saleTitle: {
    fontFamily: 'CaviarDreamsBold',
    paddingBottom: theme.spacing(2)
  },
  padder: {
    paddingBottom: theme.spacing(5)
  },
  saleButtonContainer: {
    display: 'flex',
    textAlign: 'center'
  },
  saleButton: {
    fontFamily: 'ASTERA V2',
    [theme.breakpoints.down('sm')]: {
      fontSize: 8
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 26,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 32,
    },
    margin: 'auto',
    marginBottom: theme.spacing(4)
  },
  saleLogo: {
    margin: 'auto',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      height: 150
    },
    [theme.breakpoints.up('md')]: {
      height: 200
    },
    [theme.breakpoints.up('lg')]: {
      height: 250
    }
  },
  saleLogoTrust: {
    margin: 'auto',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      height: 125
    },
    [theme.breakpoints.up('md')]: {
      height: 175
    },
    [theme.breakpoints.up('lg')]: {
      height: 225
    }
  },
  mainValuesFont: {
    fontFamily: 'ASTERA V2'
  }
})

class Sales extends Component {
  openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  Cap = (classes) => {
    <Grid container spacing={4} className={classes.mainGrid}>
      <Grid item xs={6}>
        <Typography variant='h4'>{text['main']['min']}</Typography>
        <Typography variant='h4' className={classes.mainValuesFont}>{text['main']['minValue']}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='h4'>{text['main']['max']}</Typography>
        <Typography variant='h4' className={classes.mainValuesFont}>{text['main']['maxValue']}</Typography>
      </Grid>
    </Grid>
  }

  render() {
    const {classes} = this.props;
    return (
        <Container className={classes.root}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h3' align='center' className={classes.padder}>{text['sales']['description']}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h4' align='center' className={classes.padder}>{text['sales']['infos']}</Typography>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                  <div style={{width: '100%'}} className={classes.saleButtonContainer}>
                  <img className={classes.saleLogo} alt='metamask' src={"/metamask_logo.png"} />

                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{width: '100%'}} className={classes.saleButtonContainer}>
                  <img className={classes.saleLogoTrust} alt='trust' src={"/trust_logo.png"} />

                  </div>
                </Grid>
            </Grid>
            <Grid container className={classes.saleButtonContainer}>
              <Grid item xs={6}>
                <Button onClick={() => this.openInNewTab(text['findUs']['metamaskLink'])} variant='contained' color='secondary' className={classes.saleButton}>Download Metamask</Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={() => this.openInNewTab(text['findUs']['trustLink'])} variant='contained' color='secondary' className={classes.saleButton}>Download Trust</Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
    )
  }
}

export default withStyles(styles)(Sales)