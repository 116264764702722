import React, { Component } from 'react'
import { Container, Grid, Typography, Button } from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import BackgroundImage from '../Reusable/BackgroundImage';
var text = require('../../lang/en.json')

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  mainGrid: {
    textAlign: 'center',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  mainButton: {
    fontSize: 30,
    fontFamily: 'ASTERA V2',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  mainButtonContainer: {
    display: 'flex',
    margin: 'auto',
    textAlign: 'center',
  },
  mainTitle: {
    fontFamily: 'CaviarDreamsBold',
    marginBottom: theme.spacing(2)
  },
})

class Intro extends Component {
  openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  render() {
    const {classes} = this.props;

    return (
      <BackgroundImage>
        <Container className={classes.root}>
          <Grid container spacing={4}>
            <Grid item>
              <Typography variant="h2" color="secondary" className={classes.mainTitle}>{text['main']['one-liner']}</Typography>
            </Grid>
            <Grid item>
              <Typography variant='h3'>{text['main']['description']}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4">{text['main']['info']}</Typography>
            </Grid>
            <Grid item>
              <Typography id="howto" variant="h4">{text['main']['plus-value']}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.mainButtonContainer}>
              <div style={{width: '100%'}}>
                <Button
                className={classes.mainButton}
                onClick={() => this.openInNewTab(text['findUs']['croxswap'])}
                variant='contained'
                size="large"
                color="secondary">
                  {text['main']['croxButton']}
                </Button>
                </div>
            </Grid>
            <Grid item xs={12} className={classes.mainButtonContainer}>
              <div style={{width: '100%'}}>
                <Button
                className={classes.mainButton}
                onClick={() => this.openInNewTab(text['main']['pancakeswap'])}
                variant='contained'
                size="large"
                color="secondary">
                  {text['main']['saleButton']}
                </Button>
                </div>
            </Grid>
          </Grid>
        </Container>
      </BackgroundImage>
    )
  }
}

export default withStyles(styles)(Intro)