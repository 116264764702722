import React, { useState, useEffect } from 'react'
import {withStyles} from '@material-ui/styles'
import {Button, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, Avatar, Typography, Divider, ListItemAvatar, DialogActions, Link } from '@material-ui/core'
import { useWeb3React } from '@web3-react/core'
import { useEagerConnect, useInactiveListener } from '../../Services/hooks'
import {injected, walletconnect} from '../../Services/connectors'
var text = require("../../lang/en.json")

const styles = theme => ({
  logo: {
    width: 30
  },
  paper: {
    margin: 'auto',
    maxWidth: 800
  },
  link: {
    '&:hover':{
      cursor:'pointer'
    },
    fontSize: '16px'
  },
  avatar: {
    backgroundColor: "#10101d"
  },
  button: {
    marginTop: theme.spacing(4),
    borderRadius: 40,
    width: '120px',
    overflow: 'hidden',
  },
  address: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
    fontFamily: 'ASTERA V2',
  },
  textButton: {
  }
})

function WalletDialog(props) {
  const [modal, setModal] = useState(false)
  const {classes} = props

  const context = useWeb3React()
  const {connector, account} = context

  const [activatingConnector, setActivatingConnector] = React.useState()
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  const triedEager = useEagerConnect()

  useInactiveListener(!triedEager || !!activatingConnector)

  function openInNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  function ProviderButton(props) {
    const {activate} = context
    return (
      <ListItem button onClick={() => {
        setActivatingConnector(props.event)
        activate(props.event)
        setModal(false)
      }}>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>
            <img className={classes.logo} alt={props.text} src={props.src} />
          </Avatar>
        </ListItemAvatar>
      <ListItemText primary={props.text} />
    </ListItem>
    )
  }

  function ProviderDialog(props) {
    return (
      <Dialog
      className={classes.paper}
      open={props.modal}
      onClose={() => props.setModal(false)}
      maxWidth='xs'
      fullWidth
      >
        <DialogTitle>{text['dialog']['title']}</DialogTitle>
        <Divider />
        <List>
          <ProviderButton text={"Metamask"} src="/metamask_logo.png" event={injected} />
          <ProviderButton text={"WalletConnect"} src="/walletconnect_logo.png" event={walletconnect} />
        </List>
      </Dialog>
    )
  }

  function AddressDialog(props) {
    const {account, deactivate} = context

    return (
      <Dialog className={classes.paper}
      open={props.modal}
      onClose={() => props.setModal(false)}
      maxWidth='sm'
      fullWidth
      >
        <DialogTitle>{text['dialog']['title2']}</DialogTitle>
        <Divider />
        <Button variant='text' className={classes.address} onClick={()=> {navigator.clipboard.writeText(account)}}>
          <Typography variant='subtitle1'>
            {account}
          </Typography>
        </Button>
        <DialogContent>
          <Link className={classes.link}
          onClick={() => openInNewTab(text['findUs']['bscAddressRoute'] + account)}
          color='secondary'>View on BSCscan</Link>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            deactivate()
            setModal(false)
          }} variant='outlined'>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <>
      <Button variant='contained' className={classes.button} color='secondary' onClick={() => setModal(!modal)}>
        <Typography variant='subtitle1' className={classes.textButton} noWrap>
          {account ? account : text['dialog']['button']}
        </Typography>
      </Button>
      {account ?
      <AddressDialog modal={modal} setModal={setModal} /> :
      <ProviderDialog modal={modal} setModal={setModal} />}
    </>
  )
}

export default withStyles(styles)(WalletDialog)