import React, { Component } from 'react'
import {withStyles} from '@material-ui/core/styles'

const styles = theme => ({
  background: {
    minHeight: '968px',
    height: '100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center'
  }
})

class BackgroundImage extends Component {
  constructor(props) {
    super(props)
    this.state = {imageUrl: props.imageUrl}
  }
  render() {
    const {classes} = this.props
    return(
        <div className={classes.background} style={{backgroundImage: `url(${this.state.imageUrl})`}}>
          {this.props.children}
        </div>
    )
  }
}

export default withStyles(styles)(BackgroundImage)