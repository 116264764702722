import React, {Component} from 'react'
import {withStyles} from '@material-ui/core/styles'
import { Card, CardMedia, Grid, Typography, CardActionArea } from '@material-ui/core'
import Carousel from 'react-material-ui-carousel'
import LinkIcon from '@material-ui/icons/Link'

const styles = theme => ({
  media: {
    [theme.breakpoints.up('md')]: {
      width: 400,
      height: 600,
    },
    [theme.breakpoints.down('sm')]: {
      width: 300,
      height: 400
    }
  }
})

class ImageCard extends Component {
  constructor(props) {
    super(props)
    this.state = {id: props.id, name: props.name, link: props.link, images: props.images}
  }


  openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  callDelete = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/newsImage/${this.state.id}`, {
      credentials: 'include',
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      }
    })
    .then(res => { return res.json() })
    .then(json => {
      if (json.success === 'ok') {
        this.state.deleteItem(this.state.id)
      }
    })
  }

  CardItem = (props) => {
    const {classes} = this.props
    return (
      <Card>
        <CardMedia
        className={classes.media}
        src={process.env.REACT_APP_API_URL + '/' + props.image}
        component='img'
        />
        <CardActionArea onClick={this.openInNewTab(this.state.link)}>
          <Grid container justify='space-around' alignContent='center'>
            <Grid item xs={10}>
              <Typography variant='h5'>{this.state.name}</Typography>
            </Grid>
            <Grid item xs={2}>
              <LinkIcon />
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    )
  }

  render() {
    return (
      <Carousel autoPlay={false} animation={'slide'}>
        {this.state.images.map((image, index) => (
          <this.CardItem image={image} key={index}/>
        ))}
      </Carousel>
    )
  }
}

export default withStyles(styles)(ImageCard)