import React, {Component} from 'react'
import {withStyles} from '@material-ui/core/styles'
import { Container, Tab, Tabs } from '@material-ui/core';
import { TabPanel, TabContext} from '@material-ui/lab'
import BackgroundImage from './Reusable/BackgroundImage'
import ImageNews from './Learn/ImageNews'
import SelectedNews from './Learn/SelectedNews'

const styles = theme => ({
  text: {
    fontFamily: 'ASTERA V2',
  }
})

class SignPage extends Component {
  constructor() {
    super();
    this.state = {index: "2"}
  }

  handleChange = (event, value) => {
    this.setState({index: value})
  }

  render() {
    const {classes} = this.props;
    return(
      <BackgroundImage imageUrl='background.png'>
      <Container>
        <TabContext value={this.state.index}>
        <Tabs
        value={this.state.index}
        onChange={this.handleChange}
        variant='fullWidth'>
          <Tab className={classes.text} label='Learn with Pino team (Coming Soon)' value={"1"} disabled/>
          <Tab className={classes.text} label='Check our special news selection' value={"2"}/>
        </Tabs>
        <TabPanel value={"1"} index={this.state.index}>
          <ImageNews />
        </TabPanel>
        <TabPanel value={"2"} index={this.state.index}>
          <SelectedNews />
        </TabPanel>
        </TabContext>
      </Container>
      </BackgroundImage>
    )
  }
}

export default withStyles(styles)(SignPage)