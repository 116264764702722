import React, { Component } from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Container, Grid} from '@material-ui/core'
import ImageCard from '../Reusable/ImageCard'
import BackgroundImage from '../Reusable/BackgroundImage'
import AddLearnImage from './AddLearnImage'

const styles = theme => ({})

class imageNews extends Component {
  constructor() {
    super()
    this.state = {list: []}
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/api/newsImage`, {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(res => {return res.json()})
    .then(json => {
      if (json.success === 'ok') {
        this.setState({list: json.msg})
      }
    })
  }

  deleteItem = (itemID) => {
    this.setState(prevState => {
      let newList = prevState.list.filter(item => item._id !== itemID)
      return {list: newList}
    })
  }

  addItem = (newItem) => {
    this.setState(prevState => ({list: [...prevState.list, newItem]}))
  }

  render() {
    return(
      <BackgroundImage imageUrl='/background.png'>
      <Container>
      <Grid container justify='center'>
        {sessionStorage.getItem('token') !== null &&
        <Grid item xs={12}>
          <AddLearnImage addItem={this.addItem} />
        </Grid>
        }
        <Grid item xs={12} md={'auto'}>
          {this.state.list.map((item, index) =>(
            <ImageCard key={index} id={item._id} name={item.name} images={item.images} link={item.url} deleteItem={this.deleteItem} />
          ))}
        </Grid>
      </Grid>
      </Container>
      </BackgroundImage>
    )
  }
}

export default withStyles(styles)(imageNews)