import React, { Component } from 'react'
import { Grid, Container, Typography } from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import Block from '../Reusable/RoadBlock'
import BackgroundImage from '../Reusable/BackgroundImage'
import { Timeline } from '@material-ui/lab'
var text = require('../../lang/en.json')

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 500
  },
  roadmapContainer: {
    marginBottom: theme.spacing(4)
  },
  textContainer: {
    textAlign: 'center',
    margin: theme.spacing(3)
  },
  barText: {
    '&:hover':{
      cursor:'pointer'
    },
    textDecoration: 'underline',
    display: 'inline',
  },
  roadmapTitle: {
    display: 'inline',
    textAlign: 'center',
    fontFamily: 'ASTERA V2'
  }
})

class Roadmap extends Component {
  constructor() {
    super()
    this.state = {blocksList: [
      {'name': "Q1", isDone: true},
      {'name': "Q2", isDone: true},
      {'name': "Q3", isDone: false},
      {'name': "Q4", isDone: false},
      {'name': "Q1 2022", isDone: false}
    ]}
  }

  openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  render() {
    const {classes} = this.props;
    return (
        <Container className={classes.root}>
          <Grid container>
            <Grid item xs={12} md={12} className={classes.textContainer}>
              <Typography variant='h2' style={{color: "#e08151"}} className={classes.roadmapTitle}>THE PLATFORM IS COMING SOON...</Typography>
            </Grid>
            <Grid item xs={12} className={classes.textContainer}>
              <Typography variant='h3' className={classes.barText}
              onClick={() => this.openInNewTab(text['findUs']['roadmap'])}>Discover the Roadmap</Typography>
            </Grid>
            <Grid item xs={12} className={classes.textContainer}>
              <Typography variant='h3' className={classes.barText}
              onClick={() => this.openInNewTab(text['findUs']['business'])}>The business model</Typography>
            </Grid>
          </Grid>
        </Container>
    )
  }
}

/*

            <Grid item xs={12}>
            <Timeline>
              {this.state.blocksList.map((block, index) => (
                <Block key={index} blockName={block.name} done={block.isDone} />
              ))}
            </Timeline>
            </Grid>
*/

export default withStyles(styles)(Roadmap)