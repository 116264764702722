import React, {Component} from 'react'
import {withStyles} from '@material-ui/core/styles'
import { Button, Grid, Paper, CircularProgress, TextField } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const styles = theme => ({
  container: {
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      boxShadow: '0px 0px 2px 2px ',
      cursor: 'pointer'
    }
  },
  button: {
    display: 'flex',
    margin: 'auto',
    marginTop: theme.spacing(4)
  },
  imageCard: {
    height: 300,
    width: 200
  },
  boxError: {
    boxShadow: '0px 0px 1px 1px rgb(255, 50, 50)'
  },
  iconError: {
    color: 'rgb(255, 50, 50)'
  }
})

class AddLearnImage extends Component {
  constructor() {
    super()
    this.state = {
      name: '',
      url: '',
      files: [],
      previews: [],
      displayProgress: false,
      errName: false,
      errUrl: false,
      errImage: false
    }
  }

  upload = () => {
    this.setState({
      errName: Boolean(!this.state.name),
      errUrl: Boolean(!this.state.url),
      errImage: Boolean(!this.state.files.length)
    })
    if (this.state.name && this.state.url && this.state.files.length) {
      this.setState({displayProgress: true})
      fetch(`${process.env.REACT_APP_API_URL}/api/newsImage`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({
          name: this.state.name,
          url: this.state.url
        })
      })
      .then((res) => {return res.json()})
      .then((json) => {
        console.log(json)
        let data = new FormData()
        for (let i = 0; i < this.state.files.length; i++) {
          data.append('images', this.state.files[i])
        }
        if (json.success === 'ok') {
          let id = json.msg._id
          let options = {
            method: 'PUT',
            credentials: 'include',
            headers: {'Accept': 'application/json', 'Authorization': `Bearer ${sessionStorage.getItem('token')}`},
            body: data
          }
          delete options.headers['Content-Type']
          fetch(`${process.env.REACT_APP_API_URL}/api/newsImage/${id}/image`, options)
          .then((res) => { return res.json() })
          .then((json) => {
            if (json.success === 'ok') {
              this.setState({
                name: '',
                url: '',
                files: [],
                previews: [],
                displayProgress: false
              })
            }
          })
          .catch(err => {console.log(err)})
        }
      }).catch(err => {console.log(err)})

    }
  }

  selectFiles = (event) => {
    var filesArray = []
    var previewsArray = []
    for (let i = 0; i < event.target.files.length; i++) {
        filesArray.push(event.target.files[i])
        previewsArray.push(URL.createObjectURL(event.target.files[i]))
    }
    this.setState({files: filesArray, previews: previewsArray})
    console.log(previewsArray)
  }

  deleteImage = (toDelete) => {
    this.setState(prevState => {
      var newPreviews = prevState.previews.filter(item => item !== toDelete)
      var newFiles = prevState.files.filter(item => item !== toDelete)
      return {previews: newPreviews, files: newFiles}
    })
  }

  handleChange = key => event => {
    this.setState({[key]: event.target.value})
  }

  render() {
    const {classes} = this.props
    return (
      <Grid container justify='center' alignContent='center' alignItems='center' spacing={1}>
        <Grid item xs={12} md={6}>
          <TextField
          variant='outlined'
          fullWidth
          label='Name'
          value={this.state.name}
          onChange={this.handleChange('name')}
          error={this.state.errName} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
          variant='outlined'
          fullWidth
          label='Url'
          value={this.state.url}
          onChange={this.handleChange('url')}
          error={this.state.errUrl}/>
        </Grid>
        <Grid item xs={12} md={3}>
          <input type="file" accept="image/*" multiple id="file" ref="fileUploader" style={{display: "none"}} onChange={this.selectFiles} />
          <Paper className={[classes.container, classes.imageCard, (this.state.errImage) && classes.boxError]} onClick={(e) => {this.refs.fileUploader.click()}}>
            <AddIcon className={[classes.imageCard, (this.state.errImage) && classes.iconError]} />
          </Paper>
        </Grid>
        {this.state.previews && this.state.previews.map((image, index) => (
          <Grid key={index} item xs={12} md={3}>
            <Paper className={[classes.container, classes.imageCard]} onClick={() => this.deleteImage(image)}>
              <img alt="" className={classes.imageCard} src={image} />
            </Paper>
          </Grid>
        ))}
          <Grid item xs={12} >
            {this.state.displayProgress ?
            <CircularProgress className={classes.button} /> :
            <Button variant='contained' color='secondary' onClick={() => this.upload()} size='large' className={classes.button}>Upload</Button>
            }
          </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(AddLearnImage)