import React, {Component} from 'react'
import {Card, CardMedia, CardContent, CardActionArea, Typography, Grid, Fab} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'

const styles = theme => ({
  card: {
    [theme.breakpoints.up('md')]: {
      height: 300,
    },
    [theme.breakpoints.down('sm')]: {
      height: 600,
    },
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  buttonDelete: {
    position: 'absolute',
    margin: '5px'
  },
  paragraph: {
    [theme.breakpoints.up('md')]: {
      height: 150,
    },
    [theme.breakpoints.down('sm')]: {
      height: 170
    },
    overflow: 'hidden'
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      height: 300,
    }
  },
  media: {
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: 300,
      paddingTop:'56,25%',
      margin: 'auto'
    }
  },
  text: {
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    }
  },
  link: {
    margin: theme.spacing(1)
  }
})

class LearnCard extends Component {
  constructor(props) {
    super(props)
    this.state = {id: props.id, name: props.name, desc: props.desc, link: props.link, src: props.src, type: props.type, deleteItem: props.deleteItem, delButton: false}
  }

  openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  callDelete = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/news/${this.state.id}`, {
      credentials: 'include',
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      }
    })
    .then(res => { return res.json() })
    .then(json => {
      if (json.success === 'ok') {
        this.state.deleteItem(this.state.id)
      }
    })
  }

  render() {
    const {classes} = this.props;
    return (
      <Card className={classes.card} onMouseOver={() => this.setState({delButton: true})} onMouseOut={() => this.setState({delButton: false})}>
        <Fab
        color='secondary'
        className={classes.buttonDelete}
        style={{display: (this.state.delButton && sessionStorage.getItem('token')) ? 'flex' : 'none'}}
        onClick={() => this.callDelete()}>
          <DeleteIcon />
        </Fab>
        <Grid container spacing={2} alignItems='center' alignContent='center'>
          <Grid item xs={12} md={"auto"} className={classes.container}>
            <CardMedia
              className={classes.media}
              src={this.state.src}
              title={this.state.name}
              component='img'
               />
          </Grid>
          <Grid item xs={12} md={8} >
            <CardContent>
              <Typography className={classes.text} variant='h4' noWrap>{this.state.name}</Typography>
              <Typography className={classes.paragraph} variant='h6' component='p'>{this.state.desc}</Typography>
            </CardContent>
            <CardActionArea onClick={() => this.openInNewTab(this.state.link)}>
              <Typography className={classes.link} variant='h5' noWrap>{this.state.link}</Typography>
            </CardActionArea>
          </Grid>
        </Grid>
      </Card>
    )
  }
}

export default withStyles(styles)(LearnCard)