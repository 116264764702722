import React, { Component } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@material-ui/lab'
import {withStyles} from '@material-ui/core/styles'
var text = require('../../lang/en.json')

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  blockContainer: {
    marginBottom: theme.spacing(3),
  },
  blockTitle: {
    fontFamily: 'CaviarDreamsBold',
    display: 'inline',
    marginRight: theme.spacing(1)
  },
  timeline: {
    color: '#e16b30'
  }
})

class RoadBlock extends Component {
  constructor(props) {
    super(props)
    this.state = {blockName: props.blockName, isDone: props.done}
  }

  displayBlock = () => {
    const {classes} = this.props;
    var blockText = [];
    var isFirst = true;
    for (const [key, value] of Object.entries(text['roadmap'][this.state.blockName])) {
      blockText.push(
        <div key={key}>
          {isFirst && <Typography variant='h4' color='secondary' className={classes.blockTitle}>{this.state.blockName}</Typography>}
          <Typography variant="h5" className={isFirst && classes.blockTitle}>{value}</Typography>
        </div>
      );
      isFirst = false;
    }
    return (blockText)
  }

  render() {
    const {classes} = this.props;
    return (
      <Grid container className={classes.blockContainer}>
        <TimelineItem>
          <TimelineSeparator>
            {this.state.isDone ? <TimelineDot color='secondary' /> : <TimelineDot color='secondary' variant="outlined" />}
            <TimelineConnector color='secondary' />
          </TimelineSeparator>
          <Grid item xs={12}>
          <TimelineContent>
              {this.displayBlock()}
          </TimelineContent>
          </Grid>
        </TimelineItem>
      </Grid>
    )
  }
}

export default withStyles(styles)(RoadBlock)