import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

const POLLING_INTERVAL = 12000
const RPC_URL = {1: "https://mainnet.infura.io/v3/84842078b09946638c03157f83405213", 56: "https://bsc-dataseed.binance.org/"}

export const injected = new InjectedConnector({ supportedChainIds: [1, 56] })
export const walletconnect = new WalletConnectConnector({
  rpc: RPC_URL,
  qrcode: true,
  pollingInterval: POLLING_INTERVAL
})