import React from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import CssBaseLine from '@material-ui/core/CssBaseline';
import astera_v2 from './fonts/ASTERA_V2.ttf';
import CaviarDreamsBold from './fonts/CaviarDreams_Bold.ttf';
import CaviarDreams from './fonts/CaviarDreams.ttf';
import MainPage from './Components/MainPage';
import Navbar from './Components/Navbar';
import LearnPage from './Components/LearnPage';
import SignPage from './Components/SignPage';
import { ethers } from 'ethers'
import { Web3ReactProvider } from '@web3-react/core'

const caviarDreams = {
  fontFamily: 'CaviarDreams',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('CaviarDreams'),
    url(${CaviarDreams}) format('truetype')`
}

const astera = {
  fontFamily: 'ASTERA V2',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `local('ASTERA V2'),
        url(${astera_v2}) format('truetype')`
}

const caviarDreamsBold = {
  fontFamily: 'CaviarDreamsBold',
  fontStyle: 'bold',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('CaviarDreamsBold'),
    url(${CaviarDreamsBold}) format('truetype')`
}

let muiTheme = createMuiTheme({
  palette: {
    type: 'dark',
    background: {
      paper: "#1d1d2d",
      default: '#23232d'
    },
    secondary: {
      main: "#e16b30"
    },
  },
  typography: {
    interWord: 'inter-word',
    fontFamily: 'CaviarDreams',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [caviarDreams, astera, caviarDreamsBold]
      }
    }
  }
})

const theme = responsiveFontSizes(muiTheme)

function App() {
  function getLibrary(provider) {
    const library = new ethers.providers.Web3Provider(provider)
    library.pollingInterval = 12000
    return library
  }

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <MuiThemeProvider theme={theme}>
        <CssBaseLine />
        <BrowserRouter>
        <Navbar />
        <Switch>
          <Route exact path='/' render={(props) => <MainPage {...props} />} />
          <Route exact path='/learn' render={(props) => <LearnPage {...props} />} />
          <Route exact path='/authenticate' render={(props) => <SignPage {...props} />} />
        </Switch>
        </BrowserRouter>
      </MuiThemeProvider>
    </Web3ReactProvider>
  )
}

export default App;