import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import Intro from './Main/1_Intro'
import Sales from './Main/2_Sales'
import Roadmap from './Main/3_Roadmap'
import Details from './Main/4_Details'

const styles = theme => ({
  root: {
    flexGrow:1
  }
})

class MainPage extends Component {
  constructor() {
    super()
    this.state = {components: [
      <Intro />,
      <Sales />,
      <Roadmap />,
      <Details />
    ]}
  }
  render() {
    const {classes} = this.props;
    return (
      <Grid container className={classes.root}>
        {this.state.components.map((component, index) => (
          <Grid key={index} item xs={12}>
            {component}
          </Grid>
        ))}
      </Grid>
    )
  }
}

export default withStyles(styles)(MainPage);