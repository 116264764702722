import React, {Component} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Grid, Paper, TextField, IconButton} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const styles = theme => ({
  root: {
    height: 80,
    width: '100%'
  },
  field: {
    width: '100%'
  }
})

class CreateLearnButton extends Component {
  constructor(props) {
    super(props)
    this.state = {link: "", errLink: false, addItem: props.addItem}
  }

  validURL(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  handlePush = () => {
    if (this.validURL(this.state.link)) {
      fetch(`${process.env.REACT_APP_API_URL}/api/news`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}` },
        body: JSON.stringify({
          name: this.state.link
        })
      })
      .then(res => { return res.json() })
      .then(json => {
        if (json.success === 'ok') {
          this.state.addItem(json.msg)
        }
      })
      this.setState({errLink: false});
    } else {
      this.setState({errLink: true});
    }
  }

  handleChange = event => {
    this.setState({link: event.target.value})
  }

  render() {
    const {classes} = this.props
    return(
      <Paper>
        <Grid container className={classes.root}
        spacing={1}
        justify='space-around'
        alignContent='center'>
        <Grid item xs={10}>
          <TextField variant='outlined'
          label="Please enter the article's URL"
          className={classes.field}
          value={this.state.link}
          onChange={this.handleChange}
          error={this.state.errLink} />
        </Grid>
        <Grid item xs={'auto'}>
          <IconButton onClick={() => this.handlePush()}><AddIcon size='large' /></IconButton>
        </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default withStyles(styles)(CreateLearnButton)