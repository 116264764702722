import React, { Component } from 'react'
import {withStyles} from '@material-ui/core/styles'
import { Button, Container, Grid, Typography} from '@material-ui/core'
var text = require("../../lang/en.json")

const styles = theme => ({
  detailsContainer: {
    flexGrow: 1,
    display: 'flex',
    textAlign: 'center',
    justify: 'center',
    margin: 'auto',
  },
  detailsTitle: {
    paddingBottom: theme.spacing(3)
  },
  detailsButton: {
    fontSize: 20,
    fontFamily: 'ASTERA V2',
    marginBottom: theme.spacing(3)
  }
})

class Details extends Component {
  constructor() {
    super()
    this.state = {list: [
      {name: 'Telegram', link: text['findUs']['telegramLink']},
      {name: 'Twitter', link: text['findUs']['twitterLink']},
      {name: 'Instagram', link: text['findUs']['instagramLink']}
    ]}
  }
  openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.detailsBackground}>
      <Container>
        <Grid container className={classes.detailsContainer} spacing={1} justify='space-evenly'  alignContent='center' alignItems='center'>
          <Grid item xs={12} className={classes.detailsTitle}>
            <Typography variant='h3'>{text['findUs']['title']}</Typography>
          </Grid>
          {this.state.list.map((item, index) => (
          <Grid key={index} item xs={'auto'}>
            <Button size='large' color="secondary" variant='contained' className={classes.detailsButton} onClick={() => this.openInNewTab(item['link'])}>{item['name']}</Button>
          </Grid>
          ))}
        </Grid>
      </Container>
      </div>

    )
  }
}

export default withStyles(styles)(Details)