import React, { Component } from 'react'
import {withStyles, Container, Grid, TextField, Button} from '@material-ui/core'

const styles = theme => ({
  root: {
    width: '100%'
  },
  text: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  buttonContainer: {
    display: 'flex',
    margin: 'auto',
    textAlign: 'center',
  },
  button: {
    minWidth: 200,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    fontSize: 30,
    fontFamily: 'ASTERA V2'
  }
})

class Register extends Component {
  constructor() {
    super();
    this.state = {email: '', password: '', confirm: '', errEmail: false, errPwd: false, errConfirm: false}
  }

  handleChange = key => event => {
    this.setState({[key]: event.target.value})
  }

  register = () => {
    this.setState({errEmail: (this.state.email === '')})
    this.setState({errPwd: (this.state.password === '')})
    this.setState({errConfirm: (this.state.password !== this.state.confirm)})

    if (!this.state.errEmail && !this.state.errPwd && !this.state.errConfirm) {
      fetch(`${process.env.REACT_APP_API_URL}/api/users/`, {
        credentials: 'include',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
        body: JSON.stringify({
          email: this.state.email,
          password: this.state.password,
          passwordConfirm: this.state.confirm
        })
      })
      .then(res => { return res.json() })
      .then(json => {
        if (json.success === 'ok') {
          window.location.reload(true)
        }
      })
    }
  }

  render() {
    const {classes} = this.props
    return (
      <Container>
        <Grid container className={classes.root}
        justify='center'
        alignContent='center'
        alignItems='center'
        style={{margin: 'auto'}}
        spacing={1}>
          <Grid item xs={10}>
            <TextField
            className={classes.text}
            type='email'
            variant='outlined'
            label="E-mail"
            value={this.state.email}
            onChange={this.handleChange('email')}
            error={this.state.errEmail}
            fullWidth
            />
          </Grid>
          <Grid item xs={10} md={5}>
            <TextField
            className={classes.text}
            variant='outlined'
            label="Password" type='password'
            value={this.state.password}
            onChange={this.handleChange('password')}
            error={this.state.errPwd}
            fullWidth
            />
          </Grid>
          <Grid item xs={10} md={5}>
            <TextField
             className={classes.text}
              variant='outlined'
              label="Password"
              type='password'
              value={this.state.confirm}
              onChange={this.handleChange('confirm')}
              error={this.state.errConfirm}
              fullWidth
              />
          </Grid>
          <Grid item xs={10} className={classes.buttonContainer}>
            <div style={{width: '100%'}}>
              <Button className={classes.button} size='large' variant='contained' color='secondary' onClick={() => this.register()}>REGISTER</Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

export default withStyles(styles)(Register)