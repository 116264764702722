import React, { Component } from 'react'
import {withStyles, Container, Grid, Button} from '@material-ui/core'

const styles = theme => ({
  root: {
    width: '100%',
    minHeight: 400
  },
  text: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  buttonContainer: {
    display: 'flex',
    margin: 'auto',
    textAlign: 'center',
  },
  button: {
    minWidth: 200,
    margin: theme.spacing(3),
    fontSize: 30,
    fontFamily: 'ASTERA V2'
  }
})

class Logout extends Component {
  logout = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/users/logout`, {
      credentials: 'include',
      method: 'GET',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
    })
    .then(res => { return res.json() })
    .then(json => {
      if (json.success === 'ok') {
        sessionStorage.clear()
        window.location.reload(true);
      }
    })
  }

  render() {
    const {classes} = this.props
    return (
      <Container>
        <Grid container className={classes.root}
        justify='center'
        alignContent='center'
        alignItems='center'
        style={{margin: 'auto'}}
        spacing={1}>
          <Grid item xs={10} className={classes.buttonContainer}>
            <div style={{width: '100%'}}>
              <Button className={classes.button} size='large' variant='contained' color='secondary' onClick={() => this.logout()}>LOG OUT</Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

export default withStyles(styles)(Logout)